/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import { Link } from 'gatsby'

const Bio = ({location}) => {
  return (
    <div className="bio">
      <p>
      Hey — My name is Daniel Immke. I’m a designer & developer currently building <a href="https://pickwick.app/" rel="noreferrer" target="_blank">Pickwick</a>.
      </p>
      <p>If you liked my writing, check out <Link to="/archives">more posts</Link> or subscribe.</p>
    </div>
  )
}

export default Bio
