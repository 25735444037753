import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import parse from 'html-react-parser'

import MetaData  from '../components/MetaData'
import Bio from '../components/bio'
import EmailForm from '../components/EmailForm'
import PostInfo from "../components/PostInfo"

const Article = ({ data, location }) => {
  if(location.pathname === '/article/') navigate('404');

  const { markdownRemark: post, allMarkdownRemark } = data
  const morePosts = allMarkdownRemark.nodes

  const { ref: morePostsRef, inView: morePostsInView } = useInView({
    threshold: 0,
    triggerOnce: true
  });

  return (
    <>
      <article
          itemScope
          itemType="http://schema.org/Article"
        >
        <header>
          <PostInfo date={post.frontmatter.date} timeToRead={post.timeToRead} />
          <h2 itemProp="headline">{post.frontmatter.title}</h2>
          {post.frontmatter.description && <p className="description">{post.frontmatter.description}</p>}
        </header>
          {parse(post.html)}
        <footer>
          <Bio location={location} />
          <EmailForm />
        </footer>
      </article>
      <nav className={morePostsInView ? `animate-in more-posts container` : `more-posts container`} ref={morePostsRef}>
        <h4>More Posts</h4>
        <ul>
          {morePosts.map( (post) => {
            return (
              <li key={post.fields.slug}>
                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <Link state={{ prevPath: location.href }} to={post.fields.slug} itemProp="url">
                    <header>
                      <PostInfo date={post.frontmatter.date} timeToRead={post.timeToRead} />
                      <h3 itemProp="headline">{post.frontmatter.title}</h3>
                    </header>
                    <p>{post.frontmatter.description}</p>
                  </Link>
                </article>
              </li>
            )
          })}
        </ul>
      </nav>
    </>
)}

export const Head = ({ data }) => <MetaData {...data} />

export default Article

export const query = graphql`
  query ($id: String!) {
    markdownRemark(
      id: { eq: $id }
      frontmatter: {status: {ne: "draft"}}
    ) {
      id
      timeToRead
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        description
        thumbnail
      }
    }
    allMarkdownRemark(limit: 3, sort: { fields: [frontmatter___date], order: DESC }, filter: {id: {ne: $id}, frontmatter: { status: { ne: "draft" } }}) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          description
        }
        timeToRead
      }
    }
  }
`
